import React from 'react'
import { Link } from 'gatsby'

import Image from './Image'
import './PostCard.css'

const PostCard = ({
  featuredImage,
  displayTitle,
  slug,
  title,
  categories = [],
  className = '',
  ...props
}) => (
  <Link to={slug} className={`item ${className}`}>
    <div className="image">
      <Image src={featuredImage} alt={title} />
    </div>
    <div className="dets">
      <h3>{displayTitle}</h3>
      <h5>{categories && categories.map(cat => cat.category).join(', ')}</h5>
    </div>
  </Link>
)

export default PostCard

import React from 'react'
import { Link } from 'gatsby'

import Image from './Image'

import './CallToAction.css'

const CallToAction = () => (
  <section className="cta relative">
    <div className="thin center">
      <h5>Get a consultation now</h5>
      <h2>Get in touch for an initial complimentart consult</h2>
      <Link to="/contact" className="button">
        Get in touch
      </Link>
    </div>

    <div className="background absolute">
      <Image
        src="https://ucarecdn.com/bfb9cfa5-7477-466f-9d8f-fd5fd97f5ea4/"
        alt="Get a consultation now"
      />
    </div>
  </section>
)

export default CallToAction

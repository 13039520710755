import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import ImageSlider from '../components/ImageSlider'
import CallToAction from '../components/CallToAction'
import PostCard from '../components/PostCard'
import Layout from '../components/Layout'
import './SinglePost.css'

export const SinglePostTemplate = ({
  title,
  displayTitle,
  featuredImage,
  sectionStats,
  sectionSlider,
  sectionTestimonial,
  body,
  categories = [],
  isPreview,
  posts
}) => {
  let currentCats = []
  let relatedPosts = []
  if (categories) {
    currentCats = categories.map(obj => obj.category)
  }
  if (posts) {
    relatedPosts = posts
      .filter(post =>
        post.categories.find(cat => currentCats.includes(cat.category))
      )
      .sort(function() {
        return 0.5 - Math.random()
      })
  }

  return (
    <div className="single-post">
      <article
        className="SinglePost section light"
        itemScope
        itemType="http://schema.org/BlogPosting"
      >
        <PageHeader title={displayTitle} backgroundImage={featuredImage} />

        {/* intro section */}

        <section>
          <div className="wide">
            <h3>
              {categories[0].category} - {displayTitle}
            </h3>
          </div>
        </section>

        {/* stats section */}

        {!!sectionStats.client && (
          <div className="stats yellow">
            <div className="wide quart flex">
              <div className="item">
                <h4>{sectionStats.client}</h4>
                <h5>Client</h5>
              </div>
              <div className="item">
                <h4>{sectionStats.date}</h4>
                <h5>Date</h5>
              </div>
              <div className="item">
                <h4>{sectionStats.area}</h4>
                <h5>Area</h5>
              </div>
              <div className="item">
                <h4>{sectionStats.location}</h4>
                <h5>Location</h5>
              </div>
            </div>
          </div>
        )}

        {/* gallery section */}

        <div className="slider yellow">
          <div className="wide">
            <ImageSlider slider={sectionSlider} />
          </div>
        </div>

        {/* testimonial section */}

        {!!sectionTestimonial.quote && (
          <section className="yellow testi">
            <div className="thin testimonial">
              <h4>{sectionTestimonial.quote}</h4>
              <p>- {sectionTestimonial.client}</p>
            </div>
          </section>
        )}

        {/* related posts section */}

        {!!relatedPosts && relatedPosts.length && (
          <section className="related">
            <div className="wide projects">
              <div className="center">
                <h2>Related Projects</h2>
              </div>

              <div className="item-list third flex">
                {relatedPosts.map((post, index) => (
                  <PostCard key={post.title + index} {...post} />
                ))}
              </div>
            </div>
          </section>
        )}

        <CallToAction />
      </article>
    </div>
  )
}

// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, posts } }) => {
  return (
    <Layout
      meta={post.frontmatter.meta || false}
      title={post.frontmatter.title || false}
    >
      <SinglePostTemplate
        {...post}
        {...post.frontmatter}
        body={post.html}
        posts={posts.edges.map(edge => ({
          ...edge.node.frontmatter,
          ...edge.node.fields
        }))}
      />
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        displayTitle
        sectionStats {
          client
          date
          area
          location
        }
        sectionSlider {
          img
        }
        sectionTestimonial {
          quote
          client
        }
        categories {
          category
        }
        featuredImage
      }
    }

    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            displayTitle
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
  }
`

import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { PhotoSwipe } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'

import Image from './Image'
import './ImageSlider.css'

export class ImageSlider extends React.Component {
  state = {
    loaded: false,
    isOpen: false,
    images: [],
    index: 0
  }

  close() {
    this.setState({ isOpen: false })
  }

  open(index) {
    this.setState({ isOpen: true, index: index })
  }

  fetchImageInfo = (img, index) =>
    fetch(img.img + '-/json/')
      .then(res => res.json())
      .then(
        result => {
          const newImage = {
              src: img.img,
              w: result.width,
              h: result.height
            },
            newImagesArr = [...this.state.images]
          newImagesArr[index] = newImage
          this.setState({
            images: newImagesArr
          })
          return true
        },
        error => {
          console.log(error)
          return false
        }
      )

  componentDidMount() {
    const { slider } = this.props,
      maxCount = slider.length
    let loopCount = 1

    for (let i in slider) {
      const img = slider[i]
      if (this.fetchImageInfo(img, i)) {
        if (loopCount === maxCount) {
          this.setState({ loaded: true })
        }
        loopCount++
      }
    }
  }

  render() {
    const { slider } = this.props
    var params = {
      infinite: false,
      slidesToShow: 2,
      // centerMode: true,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    }

    return (
      <div className="image-slider">
        <div className="wide">
          <Slider {...params}>
            {slider.map((item, index) => (
              <Image
                src={item.img}
                onClick={() => this.open(index)}
                alt={'image-' + index}
                key={`item${index}`}
              />
            ))}
          </Slider>
        </div>

        {this.state.loaded && this.state.images.length > 0 && (
          <PhotoSwipe
            isOpen={this.state.isOpen}
            items={this.state.images}
            options={{
              index: this.state.index,
              history: false,
              zoomEl: false,
              shareEl: false,
              counterEl: false
            }}
            onClose={e => this.close(e)}
          />
        )}
      </div>
    )
  }
}

export default ImageSlider
